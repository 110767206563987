// MK Communications
export default {
    BackendURL: 'https://server.mkcom.eastdevs.com/',
    LogoImage: '/static/logos/mkcom/logo.png',
    FaviconImage: '/static/logos/mkcom/favicon.png',
    UserLoginImage: '/static/logos/mkcom/zong.png',
    PRIMARY: {
        lighter: '#DEC156',
        light: '#DEC156',
        lightest: '#EDD57C',
        main: '#E0C05D',
        dark: '#BC973E',
        darker: '#BC973E',
        contrastText: '#fff',
    },
};
