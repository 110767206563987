import SETTINGS_OVERRIDE from './copies/mkcom'
// import SETTINGS_OVERRIDE from './copies/mcom'; //Muhammad Communication
// import SETTINGS_OVERRIDE from './copies/bcom'; //Barum Communication
// import SETTINGS_OVERRIDE from './copies/dtcom'; //Duad Telecom
// import SETTINGS_OVERRIDE from './copies/tt'; //Tele Tribe
// import SETTINGS_OVERRIDE from './copies/mkcom'; //MK Communications

let BASIC_SETTINGS = {
    TITLE: 'East Devs',
    fullTitle: 'East Devs',
    rowsPerPageList: [10, 30, 50, 100, 500],
    defaultPerPage: 100,
    // BackendURL: 'https://server.eastdevs.com/', //Hosting
    //BackendURL: 'http://127.0.0.1:1337/', //Local
    BackendURL: 'http://localhost:1337/', //Local

    LogoImage: '/static/logo.png',
    FaviconImage: '/favicon/logo.png',
    UserLoginImage: '/static/illustrations/user.png',
    ForgetPageImage: '/static/illustrations/forgot.png',

    Image404: '/static/illustrations/404.png',
    Image401: '/static/illustrations/401.webp',
    DefaultAvatar: '/static/mock-images/avatars/avatar_default.jpg',
    eastdevsLink: {
        name: 'eastdevs.com',
        link: 'https://eastdevs.com',
    },
    contacts: {
        arose: '0315-2033333',
        qasim: '0315-4411896',
    },

    banks: ['UBL', 'HBL', 'Habib Metro', 'ABL', 'MCB', 'Meezan', 'Faisal', 'Al-Falah', 'Cash', 'Mobilink Bank', 'JazzCash', 'EasyPaisa', 'SadaPay', 'NayaPay'],

    DefaultUploadedFileImage: '/static/icons/file.png',
    NotificationSound: '/static/sounds/notification.mp3',

    NotificationReload: 1000 * 30,

    allowedExtensions: [
        'png',
        'jpg',
        'jpeg',
        'mp3',
        'pdf',
        'zip',
        'rar',
        'docx',
        'doc',
        'ppt',
        'pptx',
        'txt',
    ],

    acceptImageUpload: 'image/png, image/jpg, image/jpeg',
    acceptFileUpload:
        'image/png, image/jpg, image/jpeg, audio/mp3, application/pdf, application/zip, application/doc, application/docx, application/ppt, application/pptx, application/txt',
    maxUploadFileSize: 10 * 1024 * 1024, //10 Mbs
    hideFileAlertIn: 3000,

    RANKS: {
        rso: 1,
        dso: 2,
        csr: 3,
        manager: 4,
        owner: 5,
        admin: 6,
    },

    RANKS_NAMES: ['Member', 'RSO', 'DSO', 'CSR', 'Manager', 'Owner', 'Admin'],

    //PALETTE

    PRIMARY: {
        lighter: '#ff6cb7',
        light: '#ff309a',
        lightest: '#ff8ac6',
        main: '#E40076',
        dark: '#d5006c',
        darker: '#b7005c',
        contrastText: '#fff',
    },

    SECONDARY: {
        lighter: '#e3f1d1',
        light: '#badd8b',
        main: '#99CC54',
        dark: '#7db135',
        darker: '#648e2a',
        contrastText: '#fff',
    },
};

BASIC_SETTINGS = {
    ...BASIC_SETTINGS,
    ...SETTINGS_OVERRIDE, //Comment this in local testing
};

BASIC_SETTINGS.BackendURLAPI = BASIC_SETTINGS.BackendURL + 'api/';
BASIC_SETTINGS.ResetPageImage = BASIC_SETTINGS.ForgetPageImage;
BASIC_SETTINGS.DataReload = BASIC_SETTINGS.NotificationReload;

export const SETTINGS = BASIC_SETTINGS;
